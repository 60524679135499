<template>
    <div class="homeInspectionItem">
        <CSTabBar :tabbar="tabbar" :checkedTab="checkedTab" @changeTabBar="changeTabBar"/>
        <!-- 查询 -->
        <div class="filter-panel">
            <input
                type="text"
                placeholder="搜索检查项"
                class="cs-input"
                style="margin: 0; margin-bottom: 15px;width: 240px;height: 30px "
                v-model="queryData.name"
            />
            <button
                type="button"
                class="btn btn-primary"
                style="margin-left: 30px"
                @click="query()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <!-- 客服部 -->
            <CSTable v-if="checkedTab==1" :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="openAddCheckItemDialog">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            检查项
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th style="text-align:left;padding-left:30px">序号</th>
                        <th style="text-align:center;padding-left:90px">检查项</th>
                        <th style="text-align:right;padding-right:55px">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(item,index) in checkItemList" :key="index">
                        <td style="text-align:left;padding-left:37px">{{ (index + 1) + (page - 1) * 10 }}</td>
                        <td style="text-align:center;padding-left:90px">{{ item.name }}</td>
                        <!-- <td style="text-align:right;padding-right:35px">
                            <button
                              style="width:60px"
                              type="button"
                              class="btn btn-primary "
                              @click="deleteInfo()"
                            >
                              删除
                            </button>
                        </td> -->
                        <td style="text-align:right;padding-right:30px">
                            <div
                                class="btn-group"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    style="min-width: 76px; width: 76px;"
                                >
                                    <li
                                        @click="deleteInfo(item.id)"
                                    >
                                        <a
                                            style="width: 100%;"
                                        >删除</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <!-- 工程部 -->
            <CSTable v-if="checkedTab==2" :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary sticky-right" @click="openAddCheckItemDialog">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            检查项
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th style="text-align:left;padding-left:30px">序号</th>
                        <th style="text-align:center;padding-left:90px">检查项</th>
                        <th style="text-align:right;padding-right:55px">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(item,index) in checkItemList" :key="index">
                        <td style="text-align:left;padding-left:37px">{{ (index + 1) + (page - 1) * 10 }}</td>
                        <td style="text-align:center;padding-left:90px">{{ item.name }}</td>
                        <!-- <td style="text-align:right;padding-right:35px">
                          <button
                            style="width:60px"
                            type="button"
                            class="btn btn-primary "
                            @click="deleteInfo()"
                          >
                            删除
                          </button>
                        </td> -->
                        <td style="text-align:right;padding-right:30px">
                            <div
                                class="btn-group"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    style="min-width: 76px; width: 76px;"
                                >
                                    <li
                                        @click="deleteInfo(item.id)"
                                    >
                                        <a
                                            style="width: 100%;"
                                        >删除</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <!-- 保安部 -->
            <CSTable v-if="checkedTab==3" :thead-top="filterHeight">
                <template v-slot:header>
                    <div class="table-header-panel text-right">
                        <button class="btn btn-primary" @click="openAddCheckItemDialog">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            检查项
                        </button>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th style="text-align:left;padding-left:30px">序号</th>
                        <th style="text-align:center;padding-left:90px">检查项</th>
                        <th style="text-align:right;padding-right:55px">操作</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="(item,index) in checkItemList" :key="index">
                        <td style="text-align:left;padding-left:37px">{{ (index + 1) + (page - 1) * 10 }}</td>
                        <td style="text-align:center;padding-left:90px">{{ item.name }}</td>
                        <!-- <td style="text-align:right;padding-right:35px">
                          <button
                            style="width:60px"
                            type="button"
                            class="btn btn-primary "
                            @click="deleteInfo()"
                          >
                            删除
                          </button>
                        </td> -->
                        <td style="text-align:right;padding-right:30px">
                            <div
                                class="btn-group"
                            >
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    操作
                                </button>
                                <ul
                                    class="dropdown-menu"
                                    style="min-width: 76px; width: 76px;"
                                >
                                    <li
                                        @click="deleteInfo(item.id)"
                                    >
                                        <a
                                            style="width: 100%;"
                                        >删除</a>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>
        <!-- 添加检查项 -->
        <CSDialog
            dialogWidth="520px"
            :dialogVisible="addCheckItemtVisible"
            dialogTitle="添加检查项"
            @onClose="checkItemClose"
            @onConfirm="checkItemConfirm"
        >
            <div
                slot="dialog-content"
                style="font-size: 15px; padding: 30px"
            >
                <div class="dialog-form">
                    <div class="dialog-form-item" v-for="(list,index) in lists" :key="index">
                        <div class="dialog-form-item-label" v-show="!index>0">检查项</div>
                        <div>
                            <input type="text" placeholder="请输入" v-model="list.itemName" :class="{active:index>0}"/>
                            <template v-if=" index > 0">
                                <img
                                    style="margin-left:10px;"
                                    src="../../assets/delete.png"
                                    class="delete-icon"
                                    @click="deleteCheckItemt(index)"
                                    alt=""
                                />
                            </template>
                        </div>
                    </div>


                    <div class="addCheckItemt">
                        <button class="btn btn-primary add" @click="addCheckItemt">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menujiahao"></use>
                            </svg>
                            <span>添加</span>
                        </button>
                    </div>
                </div>
            </div>
        </CSDialog>
    </div>
</template>
<script>
import {
    addCheckItem,
    queryCheckItem,
    deleteCheckItem,
}
    from "@/requestUrl"
import {DEPARTMENT_TYPES, JOB_LEVEL} from "@/constant/index";
import Pagination from "@/components/Pagination";
import CSTabBar from "@/components/common/CSTabBar";
import CSDialog from '@/components/common/CSDialog';
import CSTable from "@/components/common/CSTable";

export default {
    name: 'homeInspectionItem',
    components: {
        CSTable,
        CSTabBar,
        CSDialog,
        Pagination,
    },
    data() {
        return {
            page: null,
            filterHeight: 0,
            total: '',
            addCheckItemtVisible: false,
            queryData: {
                name: ''
            },
            tabbar: {
                1: "客服部",
                2: "工程部",
                3: "保安部",
            },
            dutyTypeData: {
                1: "1004",
                2: "1003",
                3: "1006",
            },
            checkedTab: 1,
            checkItemName: [],
            lists: [
                {itemName: ''}
            ],
            checkItemList: []
        }
    },
    activated() {
        this.query()
        this.$vc.on(this.$route.path, "pagination_page", "event", page => {
            this.page = page
            this.query(page);
            console.log(page);
        })

    },
    mounted() {
        this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        window.addEventListener('resize', () => {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        })
    },
    created() {
        window.addEventListener("keydown", this.queryDown);
    },
    beforeDestroy() {
        window.removeEventListener("keydown", this.queryDown);
    },
    deactivated(){
        window.removeEventListener("keydown", this.queryDown);
    },
    methods: {
        queryDown(e) {
            if (e.keyCode == 13) {
                this.query();
            }
        },
        //改变tabBar
        changeTabBar(tab) {
            this.checkedTab = tab
            this.queryData.name = ''
            this.query()

        },
        query(pageNo = 1, pageSize = 10) {
            this.page = pageNo
            this.$fly.post(queryCheckItem, {
                dutyType: this.dutyTypeData[this.checkedTab],
                pageNo,
                pageSize,
                ...this.queryData,
            })
                .then(res => {
                    if (res.code !== 0) {
                        return;
                    }
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, 'pagination', 'init', {
                            pageSize,
                            total: res.data.total,
                            currentPage: pageNo,
                        })
                    }
                    this.checkItemList = res.data.data
                    this.total = res.data.total
                })

        },
        // 添加检查项对话框
        openAddCheckItemDialog() {
            this.addCheckItemtVisible = true
        },
        checkItemClose() {
            this.addCheckItemtVisible = false
            this.lists = [
                {itemName: ''}
            ]
        },
        // 确定添加检查项
        checkItemConfirm() {
            this.checkItemName = this.lists.map((val) => {
                return val.itemName.trim()
            })
            let flag = false
            this.checkItemName.forEach((item) => {
                if (item == '') {
                    this.$vc.toast('检查项不能为空');
                } else if (this.isRepeat(this.checkItemName)) {
                    this.$vc.toast('此检查项已存在');
                } else {
                    flag = true
                }
            })
            if (flag) {
                this.$fly.post(
                    addCheckItem,
                    {
                        dutyType: this.dutyTypeData[this.checkedTab],
                        dutyName: this.tabbar[this.checkedTab],
                        createUser: JSON.parse(window.localStorage.getItem('userInfo')).id + '',
                        checkItemName: this.checkItemName
                    }
                )
                    .then((res) => {
                        if (res.code !== 0) {
                            return
                        }
                        this.$vc.toast('添加成功');
                        this.lists = [{itemName: ''}]
                        this.addCheckItemtVisible = false
                        this.query()
                    })
            }
        },
        // 判断是否有重复检查项
        isRepeat(arr) {
            let tempData = []
            let flag = false
            arr.forEach((item) => {
                if (tempData.indexOf(item) == -1) {
                    tempData.push(item)
                } else {
                    return flag = true
                }
            })
            return flag
        },
        // 添加按钮
        addCheckItemt() {
            let cope = {
                itemName: ""
            }
            this.lists.push(cope);

        },
        // 删除按钮
        deleteCheckItemt(index) {
            this.lists.splice(index, 1);
        },
        // 删除检查项
        deleteInfo(id) {
            let jobLevel = JSON.parse(window.localStorage.getItem('userInfo')).jobLevel
            let dutyType = JSON.parse(window.localStorage.getItem('userInfo')).dutyType
            this.$CSDialog.confirm({
                title: '提示',
                message: '确定删除吗?',
                onConfirm: () => {
                    if (jobLevel == JOB_LEVEL.SUPERVISOR && dutyType == DEPARTMENT_TYPES.CUSTOMER_SERVICE) {
                        this.$fly.get(`${deleteCheckItem}?id=${id}`,
                            {},
                            {
                                headers: {
                                    "x-operator-id": this.$vc.getCurrentStaffInfo().id
                                }
                            }
                        )
                            .then(res => {
                                if (res.code !== 0) {
                                    return;
                                }
                                this.$vc.toast('删除成功');
                                this.$CSDialog.instance.closeDialog();
                                this.query()
                            })
                    } else {
                        this.$vc.toast('当前用户没有权限');
                        this.$CSDialog.instance.closeDialog();
                    }

                }
            })

        },
    }
}
</script>
<style scoped lang="stylus">
.dialog-form
    padding 2px 30px
    font-size 24px
    color #000

    &-item
        &:not(:last-of-type)
            margin-bottom 23px

        &-label
            width 72px
            height 33px
            text-align right
            margin-right 40px
            display inline-block
            vertical-align middle
            line-height 33px

        & > div
            display inline-block
            vertical-align middle

            input
                &::placeholder
                    color #999
                width 250px
                height 40px
                border-radius 4px
                padding 0 5px
                border 1px solid #979797
                vertical-align middle
                padding-left 10px

.addCheckItemt
    margin-top 20px
    margin-left 112px
    font-size 24px !important

.add
    font-size 24px !important
    padding 6px 10px
    display flex
    flex-flow row nowrap
    align-items center
    justify-content center

    .icon
        font-size 18px
        vertical-align middle

    span
        line-height 1
        vertical-align middle
        margin-left 6px

.active
    margin-left 112px
</style>
